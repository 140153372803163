import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const easeOutQuad = (t, b, c, d) => (-c * (t/=d) * (t-2) + b); // eslint-disable-line
const easeInQuad = (t, b, c, d) => (c*(t/=d)*t + b); // eslint-disable-line
const easeOutCirc = (t, b, c, d) => (c * Math.sqrt(1 - (t=t/d-1)*t) + b); // eslint-disable-line
const easeInCirc = (t, b, c, d) => (-c * (Math.sqrt(1 - (t/=d)*t) - 1) + b); // eslint-disable-line

class ElementorCounter extends PureComponent {
  static propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    'data-delimiter': PropTypes.string.isRequired,
    'data-duration': PropTypes.string.isRequired,
    'data-to-value': PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.counter = React.createRef();
    this.timeout = null;
  }

  componentDidMount() {
    const { current: counter } = this.counter;
    const {
      'data-duration': duration,
      'data-to-value': toValue,
    } = this.props;

    const to = parseInt(toValue, 10);
    const dur = parseInt(duration, 10);
    const start = parseInt(counter.innerHTML, 10);
    const steps = Math.round(dur / 16);

    this.timeout = setTimeout(this.countCounter, 0, {
      rest: steps,
      steps,
      to,
      dur,
      step: (to - start) / steps,
      count: start,
    });
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    this.timeout = null;
  }

  countCounter = ({ rest, to, count, step, dur, steps, total = 0, last = 0, }) => { // eslint-disable-line
    const { current: counter } = this.counter;

    if (rest > 0) {
      const current = (steps - rest);
      const calc = Math.round(easeInCirc(current, 0, dur, steps));
      const time = Math.abs(last - calc);
      counter.innerHTML = Math.floor(count);

      this.timeout = setTimeout(this.countCounter, time, {
        rest: (rest - 1),
        to,
        step,
        dur,
        steps,
        count: count + step,
        total: total + time,
        last: calc,
      });
    } else {
      counter.innerHTML = to;
      clearTimeout(this.runner);
      this.runner = null;
    }
  };

  render() {
    const { className, children, ...props } = this.props;
    return (
      <span className={className} ref={this.counter} {...props}>{children}</span>
    );
  }
}

export default ElementorCounter;
