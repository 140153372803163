import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';


class Teaser extends PureComponent {
  constructor(props) {
    super(props);

    this.box = React.createRef();
    this.title = React.createRef();
    this.image = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.checkScroll);
    this.checkScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.checkScroll);
  }

  checkScroll = () => {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const { current: title } = this.title;
    const { current: box } = this.box;
    const { current: image } = this.image;
    const { offsetHeight: total } = box;
    const scrolled = Math.min(total, top);
    const percent = Math.round((100 / total) * scrolled);
    const opacity = (100 - percent) / 100;

    if (`requestAnimationFrame` in window) {
      requestAnimationFrame(() => {
        box.style.opacity = opacity;
        image.style.top = `-${percent}px`;
        title.style.top = `-${percent}px`;
      });
    } else {
      setTimeout(() => {
        box.style.opacity = opacity;
        image.style.top = `-${percent}%`;
        title.style.top = `-${percent}%`;
      }, 16);
    }

    if (top && !title.classList.contains('teaser__title--hidden')) {
      title.classList.add('teaser__title--hidden');
    } else if (!top) {
      title.classList.remove('teaser__title--hidden');
    }

    if (percent === 100 && !box.classList.contains('teaser__img--hidden')) {
      box.classList.add('teaser__img--hidden');
    } else {
      box.classList.remove('teaser__remove--hidden');
    }

    return true;
  };


  render() {
    const { title, fluid, alt } = this.props;

    return (
      <>
        <div className="teaser__box" ref={this.box}>
          <Img className="teaser__phantom" fluid={fluid} alt={alt || title} />
          <div className="teaser__img" ref={this.image}>
            <Img className="teaser__img--giw" alt={alt || title} fluid={fluid} />
          </div>
          <div className="teaser__title" ref={this.title}>
            <h1><span dangerouslySetInnerHTML={{ __html: title }} /></h1>
          </div>
        </div>
      </>
    );
  }
}

Teaser.propTypes = {
  alt: PropTypes.string,
  title: PropTypes.string.isRequired,
  fluid: PropTypes.shape({}).isRequired,
};

Teaser.defaultProps = {
  alt: '',
};


export default Teaser;
