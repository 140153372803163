import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ElementorToggle extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);

    this.toggle = React.createRef();
    this.list = [];
    this.index = 0;
    this.listener = [];
  }

  componentDidMount() {
    const { current: toggle } = this.toggle;
    this.list = toggle.querySelectorAll('.elementor-tab-title');

    toggle.querySelectorAll('a').forEach((node) => {
      node.addEventListener('click', (e) => {
        e.preventDefault();
        return false;
      });
    });

    this.list.forEach((node, i) => {
      this.listener[i] = this.toggleMenu(i);
      node.addEventListener('click', this.listener[i]);
    });
  }

  componentWillUnmount() {
    this.list.forEach((node, i) => node.removeEventListener('click', this.listener[i]));
  }

  toggleMenu = index => () => {
    this.list.forEach((node, i) => {
      if (index === i && !node.classList.contains('elementor-active')) {
        node.classList.add('elementor-active');
      } else if (index === i && node.classList.contains('elementor-active')) {
        node.classList.remove('elementor-active');
      }
    });
  };

  render() {
    const { children, ...props } = this.props;

    return (
      <div {...props} ref={this.toggle}>{children}</div>
    );
  }
}

export default ElementorToggle;
