import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Image from 'gatsby-image';

import unified from 'unified';
import parser from 'rehype-parse';
import toH from 'hast-to-hyperscript';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Teaser from '../components/teaser';
import Slider from '../components/slider';

import ElementImg from '../components/element/img';
import ElementorTabs from '../components/elementor/tabs';
import ElementorSlider from '../components/elementor/slider';
import ElementorToggle from '../components/elementor/toggle';
import ElementorScript from '../components/elementor/script';
import ElementorGallery from '../components/elementor/gallery';
import ElementorCounter from '../components/elementor/counter';
import ElementorProgress from '../components/elementor/progress';

const { createElement } = React;

const ExternalLink = ({ href, children, ...props }) => (
  // eslint-disable-next-line
  <a target="_blank" href={href} {...props}>
    {children}
  </a>
);

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

function rehype2react(options) {
  const settings = options || {};
  const components = settings.components || {};

  function h(name, props, children) {
    return components[name] ? components[name]({ ...props, children }) : createElement(name, props, children);
  }

  function compiler(node) {
    if (node.type === 'root') {
      if (node.children.length === 1 && node.children[0].type === 'element') {
        node = node.children[0];
      } else {
        node = {
          type: 'element',
          tagName: 'div',
          properties: node.properties || {},
          children: node.children,
        };
      }
    }

    return toH(h, node, settings.prefix);
  }

  this.Compiler = compiler;
}

const EXTERNAL_CHECK = /^(https?:\/\/|mailto:|tel:)/;

const onDragStart = (e) => e.preventDefault();

const getProcessor = ({ images, sources, srcUrl }) =>
  unified()
    .use(parser, {
      fragment: true,
    })
    .use(rehype2react, {
      components: {
        a: ({ href, ...props }) =>
          EXTERNAL_CHECK.test(href)
            ? createElement(ExternalLink, { href, ...props })
            : createElement(Link, { to: String(href).replace(/\/$/, ''), ...props }),
        script: (props) => createElement(ElementorScript, props),
        img: ({ alt, src, className }) => {
          const img = Object.keys(images).find((key) => key.includes(src));
          return images[img]
            ? createElement(Image, {
                onDragStart,
                className,
                alt,
                fluid: images[img],
              })
            : null;
        },
        span: ({ className, ...props }) => {
          switch (className) {
            case 'elementor-counter-number':
              return createElement(ElementorCounter, { className, ...props });
            default:
              return createElement('span', { className, ...props });
          }
        },
        div: ({ className, ...props }) => {
          switch (className) {
            case 'elementor-toggle':
            case 'elementor-accordion':
              return createElement(ElementorToggle, { className, ...props });
            case 'elementor-image-gallery':
              return createElement(ElementorGallery, { className, ...props, sources, srcUrl });
            case 'elementor-tabs':
              return createElement(ElementorTabs, { className, ...props });
            case 'elementor-progress-bar':
              return createElement(ElementorProgress, { className, ...props });
            default:
              return String(className).includes('elementor-widget-image-carousel')
                ? createElement(ElementorSlider, { className, ...props, sources })
                : createElement('div', { className, ...props });
          }
        },
      },
    });

const IndexPage = ({
  data: {
    wpgraphql: {
      pageBy: {
        title,
        content,
        elementorImages: images,
        elementorSources: sources,
        featuredImage: media,
        tags,
        acfSeo,
        acfSlider: { slider },
        excerpt,
      },
      generalSettings: { url: srcUrl },
    },
  },
}) => {
  const imgMapping = (images || []).reduce(
    (
      list,
      {
        url,
        image: {
          childImageSharp: { fluid },
        },
      }
    ) => ({
      ...list,
      [url]: fluid,
    }),
    {}
  );

  const srcMapping = (sources || []).reduce(
    (list, { url, alt, source }) => ({
      ...list,
      [url]: {
        url,
        alt,
        source,
      },
    }),
    {}
  );

  return (
    <Layout>
      <SEO
        title={(acfSeo && acfSeo.title) || title}
        description={(acfSeo && acfSeo.description) || excerpt}
        keywords={tags.edges.map(({ name }) => name) || []}
      />
      {media && (!slider || !slider) && (
        <Teaser alt={media.alt_text} title={media.title || title} fluid={media.imageFile.childImageSharp.fluid} />
      )}
      {slider && <Slider slides={slider} />}
      <main>
        {!media && <h1 className="main__title container" dangerouslySetInnerHTML={{ __html: title }} />}
        <div className="main__content">
          {content &&
            getProcessor({ images: imgMapping, sources: srcMapping, srcUrl }).processSync(
              content
                .replace(new RegExp(srcUrl, 'g'), '')
                .replace(/<!--[^\\[].+-->/g, '')
                .replace(/[\r\n\t]+/g, ' ')
                .replace(/>[\s]+</g, '><')
                .replace(/[\s]+/g, ' ')
            ).contents}
        </div>
      </main>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export const pageQuery = graphql`
  query pageQuery($pageId: Int) {
    wpgraphql {
      generalSettings {
        url
      }
      pageBy(pageId: $pageId) {
        id
        content
        elementorImages {
          url
          image {
            childImageSharp {
              fluid(maxWidth: 640) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        elementorSources {
          url
          alt
          source {
            publicURL
            id
          }
        }
        slug
        status
        link
        excerpt
        title
        pageId
        menuOrder
        tags {
          edges {
            node {
              name
              slug
              id
              link
            }
          }
        }
        acfSeo {
          title
          description
        }
        acfSlider {
          slider {
            id
            title
            altText
            description
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1920, maxHeight: 1080) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        featuredImage {
          id
          mediaItemId
          slug
          title
          caption
          altText
          sourceUrl
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1080) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
