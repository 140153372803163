import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ElementorTabs extends PureComponent {
  static propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);

    this.tabs = React.createRef();
    this.list = [];
    this.content = [];
    this.index = 0;
    this.listener = [];
  }

  componentDidMount() {
    const { current: tabs } = this.tabs;

    this.list = Array.from(tabs.querySelectorAll('.elementor-tab-title'));
    this.content = Array.from(tabs.querySelectorAll('.elementor-tab-content'));

    this.list[0].classList.add('elementor-active');
    this.content[0].classList.add('elementor-active');

    tabs.querySelectorAll('a').forEach((node) => {
      node.addEventListener('click', (e) => {
        e.preventDefault();
        return false;
      });
    });

    this.list.forEach((node, i) => {
      this.listener[i] = this.tabsMenu(i);
      node.addEventListener('click', this.listener[i]);
    });
  }

  componentWillUnmount() {
    this.list.forEach((node, i) => node.removeEventListener('click', this.listener[i]));
  }

  tabsMenu = index => () => {
    this.list.forEach((node, i) => {
      if (index === i && !node.classList.contains('elementor-active')) {
        node.classList.add('elementor-active');
        this.content[i].classList.add('elementor-active');
      } else if (node.classList.contains('elementor-active')) {
        node.classList.remove('elementor-active');
        this.content[i].classList.remove('elementor-active');
      }
    });
  };

  render() {
    const { children, ...props } = this.props;

    return (
      <div ref={this.tabs} {...props}>{children}</div>
    );
  }
}

export default ElementorTabs;
