import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Slick from 'react-slick';


class Slider extends PureComponent {
  constructor(props) {
    super(props);

    this.slider = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.checkScroll);
    this.checkScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.checkScroll);
  }

  checkScroll = () => {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const { current: slider } = this.slider;
    const { offsetHeight: total } = slider;
    const scrolled = Math.min(total, top);
    const percent = Math.round((100 / total) * scrolled);
    const opacity = (100 - percent) / 100;

    if (`requestAnimationFrame` in window) {
      requestAnimationFrame(() => {
        slider.style.opacity = opacity;
        slider.style.top = `-${percent}px`;
      });
    } else {
      setTimeout(() => {
        slider.style.opacity = opacity;
        slider.style.top = `-${percent}%`;
        //
      }, 16);
    }

    if (percent === 100 && !slider.classList.contains('slider__box--hidden')) {
      slider.classList.add('slider__box--hidden');
    } else if (!top) {
      slider.classList.remove('slider__box--hidden');
    }

    return true;
  };


  render() {
    const { slides } = this.props;

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      pauseOnHover: false,
    };

    return (
      <div className="slider__box" ref={this.slider}>
        <Slick {...settings}>
          {slides.map((slider) => {
            const { id, description, altText, title } = slider;
            const { fluid } = slider.imageFile.childImageSharp;

            return (
              <div className="slider__slide" key={id} ref={this.slider}>
                <div className="slider__img">
                  <Img className="slider__img--giw" alt={altText || title} fluid={fluid} />
                </div>
                <div className="slider__title">
                  <h1><span dangerouslySetInnerHTML={{ __html: title }} /></h1>
                </div>
              </div>
            );
          })}
        </Slick>
      </div>
    );
  }
}

Slider.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Slider;
