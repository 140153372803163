import React from 'react';
import PropTypes from 'prop-types';

const ElementorProgress = ({ className, 'data-max': max, children }) => (
  <div className={className} style={{ width: `${max}%` }}>{children}</div>
);

ElementorProgress.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  'data-max': PropTypes.string.isRequired,
};

export default ElementorProgress;
